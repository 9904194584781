































































































































































































import { Component, Vue, Watch } from 'vue-property-decorator'
import {
  BBadge,
  BLink,
  BFormInput,
  BPagination,
  BTable,
  BSidebar,
  VBToggle,
  BCard,
  BSpinner,
  BMedia,
  BAvatar,
  BDropdown,
  BDropdownItem,
  BRow,
  BCol
} from 'bootstrap-vue'
import VSelect from 'vue-select'

import { defaultPagination, Pagination } from '@/models/General'
import { Action } from '@/store/decorators'
import { CustomerModule } from '@/store/modules/customerModule'
import { avatarText } from '@core/utils/filter'
import {
  defaultParamsPayload,
  ModelPayload,
  ParamsPayload
} from '@/models/Payloads'
import { mixins } from 'vue-class-component'
import MixinFunctions from '@/mixins/mixinFunctions'
import AdminUpdateCustomerSidebar from '@/views/admin/customers/AdminUpdateCustomerSidebar.vue'
import { UserRoleEnums } from '@/enums/User'

@Component({
  name: 'AdminCustomersList',
  components: {
    BBadge,
    BLink,
    BFormInput,
    BPagination,
    BTable,
    BCard,
    BSpinner,
    BMedia,
    BAvatar,
    BDropdown,
    BDropdownItem,
    BRow,
    BCol,
    AdminUpdateCustomerSidebar,
    BSidebar,
    VSelect
  },
  directives: {
    'b-toggle': VBToggle
  }
})
export default class AdminCustomersList extends mixins(Vue, MixinFunctions) {
  @Action(CustomerModule, 'fetchAdminCustomerUsers')
  private fetchAdminCustomerUsers!: (
    payload: ModelPayload
  ) => Promise<Pagination>

  $refs!: { refCustomerUsersTable }

  private fields: object = [
    { key: 'user' },
    { key: 'email' },
    { key: 'roles' },
    { key: 'account_status', label: 'Status' },
    { key: 'actions', thStyle: { width: '1%' } }
  ];

  private userRole: string = UserRoleEnums.Customer
  private customerPagination: Pagination = defaultPagination()
  private avatarText: object = avatarText
  private paramsPayload: ParamsPayload = defaultParamsPayload()
  private currentPage: number = 1
  private perPage: number = 10
  private perPageOptions: number[] = [10, 25, 50, 100]
  private totalData: number = 0
  private searchQuery: string = ''
  private searchQueryTimeout: any = null

  private created () {
    // TODO need to load the query string at the first load
    this.paramsPayload = this.loadQueryString(this.paramsPayload)
  }

  private userData: object = {};
  private passCustomerData (userData) {
    this.userData = userData
  }

  private async loadCustomers () {
    try {
      this.customerPagination = await this.fetchAdminCustomerUsers({
        params: this.paramsPayload
      })

      this.totalData = this.customerPagination.total

      return this.customerPagination.data
    } catch (error) {
      // console.error(error)
    }
  }

  get dataMeta () {
    const localItemsCount = this.$refs.refCustomerUsersTable
      ? this.$refs.refCustomerUsersTable.localItems.length
      : 0

    return {
      from: this.perPage * (this.currentPage - 1) + (localItemsCount ? 1 : 0),
      to: this.perPage * (this.currentPage - 1) + localItemsCount,
      of: this.totalData
    }
  }

  @Watch('perPage')
  @Watch('currentPage')
  private refreshTable () {
    this.paramsPayload.perPage = this.perPage
    this.paramsPayload.page = this.currentPage
    clearTimeout(this.searchQueryTimeout)
    this.$refs.refCustomerUsersTable.refresh()
  }

  @Watch('searchQuery')
  private onSearchQuery () {
    this.paramsPayload.q = this.searchQuery
    clearTimeout(this.searchQueryTimeout)

    this.searchQueryTimeout = setTimeout(() => {
      this.refreshTable()
    }, 1000)
  }
}
